
import { ref } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";

// 引入swiper的js
// 引入swiper的js
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.min.css";
export default {
  name: "CarouselTwoTool",
  watch: {
    list: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(this.$refs.Carouseltop, {
            slidesPerView: "auto",
            spaceBetween: 0,
            loopAdditionalSlides: 6,
            // autoplay: {
            //   delay: 2000,
            //   stopOnLastSlide: false,
            //   disableOnInteraction: true,
            // },
            centeredSlides: true,
            // loop: true,
            observer: true,
            observeParents: true,
            coverflowEffect: {
              rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
              stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
              depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
              modifier: 0.1, //depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
              slideShadows: false, //开启slide阴影。默认 true。
            },

            // 如果需要分页器
            // pagination: {
            //   el: ".swiper-pagination",
            //   clickable: true,
            //   type: "bullets",
            // },

            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        });
      },
    },
  },
  data() {
    return {
      radio: "1",
      radioVal: 1,
      aimQus: 0,
      questions: store.state.questions,
    };
  },
  props: ["postaimqus", "correct"],
  setup(props, any) {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age };
  },
  methods: {
    // jump() {},
    nexStep() {
      const aimQus = this.postaimqus;
      console.log("aimQus10101010", aimQus);
      if (aimQus == 0) {
        if (this.radioVal == 1) {
          this.chanti();
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 0; //0开始 0-4
          this.chanti();
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 1) {
        if (this.radioVal == 4) {
          this.aimQus = 2; //0开始 0-4
          this.chanti(this.aimQus);
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 1; //0开始 0-4
          this.chanti(this.aimQus);
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 2) {
        if (this.radioVal == 3) {
          this.aimQus = 3; //0开始 0-4
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 2; //0开始 0-4
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 3) {
        if (this.radioVal == 1) {
          this.aimQus = 4; //0开始 0-4
          this.pinggu(this.aimQus);
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 4; //0开始 0-4
          this.pinggu(this.aimQus);
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 4) {
        if (this.radioVal == 1) {
          this.aimQus = 5; //0开始 0-4
          this.fangan(this.aimQus);
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          this.aimQus = 5; //0开始 0-4
          this.fangan(this.aimQus);
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (aimQus == 5) {
        this.$router.push({
          name: "report",
          // query: { postaimqus: "2", correct: "two" },
        });
        //alert("");
        // if (this.radioVal == 1) {
        //   this.aimQus = 6; //0开始 0-4
        //   this.sikao(this.aimQus);
        //   console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        // } else {
        //   this.aimQus = 6; //0开始 0-4
        //   this.sikao(this.aimQus);
        //   console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        // }\
        // this.aimQus = 6;
        // this.$emit("onSiKao", [this.aimQus, this.radioVal]);
        // console.log(
        //   this.aimQus + "11111111111111111111111111111111111111111111111"
        // );
      }
      // this.$router.push({
      //   name: "about",
      //   query: {
      //     aimQus: this.aimQus,
      //   },
      // });
      console.log("第几道题:" + this.postaimqus);
      console.log("你的选择:" + this.radioVal);
      this.$emit("onChanged", [this.aimQus, this.radioVal]);
    },
    chanti() {
      console.log("第几道题:" + "888888888");
      this.$router.push({
        name: "chati",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    pinggu() {
      console.log("第几道题:" + "111111111111111");
      this.$router.push({
        name: "pinggu",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    fangan() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "fangan",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    sikao() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "sikao",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    shikaoone() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "shikaoone",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    shikaotwo() {
      console.log("第几道题:" + "55555555");
      this.$router.push({
        name: "shikaotwo",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
  },
};
