
import { ref } from "vue";
import store from "../store/index";
import { useRouter } from "vue-router";
// import CarouselThree from "@/components/CarouselThree.vue";
import { reactive } from "vue";
export default {
  components: {
    // CarouselThree,
  },
  data() {
    return {
      radio: "1",
      radioVal: 0,
      aimQus: 0,
      questions: store.state.questions,
    };
  },
  props: ["postaimqus"],
  setup(props, any) {
    const imgs = reactive([
      {
        id: 0,
        url: " /static/img/ad1.jpg",
      },
      {
        id: 1,
        url: " /static/img/ad2.jpg",
      },
      {
        id: 2,
        url: " /static/img/ad3.jpg",
      },
    ]);
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age, imgs };
  },
  methods: {
    // jump() {},
    nexStept() {
      const aimQus = this.$route.query.aimQus;

      if (this.postaimqus == 0) {
        if (this.radioVal == 1) {
          this.aimQus = 1; //0开始 0-4
        } else {
          this.aimQus = 1; //0开始 0-4
          console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (this.postaimqus == 1) {
        //alert("32423");
        if (this.radioVal == 3) {
          console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          // this.$router.push({
          //   path: "/correct",
          //   query: { postaimqus: "1", correct: "2" },
          // });
        }
      } else if (this.postaimqus == 2) {
        if (this.radioVal == 3) {
          // this.aimQus = 3; //0开始 0-4
          // console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          // this.aimQus = 2; //0开始 0-4
          // console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      } else if (this.postaimqus == 3) {
        if (this.radioVal == 1) {
          // this.aimQus = 4; //0开始 0-4
          // console.log(this.aimQus + "继续闯关,显示成功,传参继续探寻");
        } else {
          // this.aimQus = 4; //0开始 0-4
          // console.log(this.aimQus + "闯关失败，显示失败,传参继续探寻");
        }
      }

      // if (this.postaimqus == 4) {
      //   this.aimQus = 5;
      // }

      if (this.postaimqus == 5) {
        this.$router.push({
          path: "/report",
          // query: { postaimqus: "1", correct: "two" },
        });
      }

      this.$emit("onChanged", [this.postaimqus, this.radioVal]);
    },
  },
};
