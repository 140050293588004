<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about?aimQus=0">About</router-link>
</nav> -->
  <router-view />
  <div class="img-box">
    <img
      ref="imgRef"
      class="tupian"
      id="tupian"
      :class="{ rotate: playStatus }"
      src="/static/img//music.png"
      alt="music"
      @click="controlMusic"
    />
    <audio
      ref="audioRef"
      :src="musicUrl"
      id="audioRef"
      loop
      @play="onPlay"
    ></audio>
  </div>

  <div class="shenpi">编号 PM-CN-FLX-24-00350，有效期至13 Nov 2029</div>
  <div class="shenpi2">
    本材料并非广告，旨在向且仅向医疗保健专业人士提供科学信息。如果您不是医疗保健专业人士，请勿阅读或传播其中的内容。
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "backMusic",
  data() {
    return {
      topshenpi: "",
    };
  },
  setup() {
    const audioRef = (ref < null) | (HTMLAudioElement > null);
    const imgRef = (ref < null) | (HTMLImageElement > null);
    let playStatus = ref(true);
    const musicImg = ref({
      play: "static/img/music.png",
      pause: "static/img/stopMusic.png",
    });
    const musicUrl = "/static/img/music.mp3";
    const controlMusic = () => {
      console.log("pausesdfasdfasdfasdfasdf" + audioRef.value);

      // if (audioRef.value && imgRef.value) {
      var audio = document.getElementById("audioRef");
      var img = document.getElementById("tupian");

      console.log("playStatus:" + playStatus.value);
      if (playStatus.value) {
        // audio.play();
        // audio.src = musicImg.value["pause"];
        // audio.src = musicImg.value["pause"];
        audio.pause();
        playStatus = ref(false);
        img.src = musicImg.value["pause"];
      } else {
        // audio.pause();
        // audio.src = musicImg.value["pause"];
        // var img = document.getElementsByClassName("tupian")[0];
        // img.className = "rotate";
        // audio.src = musicImg.value["play"];
        // audio.src = musicImg.value["[play]"];
        // audio.src = musicImg.value["pause"];
        audio.play();
        playStatus = ref(true);
        img.src = musicImg.value["play"];
      }
      // }
      // playStatus.value = !playStatus.value;
    };
    return { musicUrl, audioRef, controlMusic, imgRef, playStatus };
  },
  mounted() {
    this.$refs.audioRef.play();
    // this.$bus.on("topshenpi", (value) => {
    //   console.log(value);
    // });
  },
};

//--创建页面监听，等待微信端页面加载完毕 触发音频播放
document.addEventListener("DOMContentLoaded", function () {
  function audioAutoPlay() {
    var audio = document.getElementById("audioRef");
    audio.play();
    console.log("999");
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        audio.play();
        console.log("play111");
      },
      false
    );
  }
  audioAutoPlay();
});
//--创建触摸监听，当浏览器打开页面时，触摸屏幕触发事件，进行音频播放
document.addEventListener(
  "touchstart",
  function () {
    function audioAutoPlay() {
      var audio = document.getElementById("audioRef");
      audio.play();
      var img = document.getElementsByClassName("tupian")[0];
      // img.className = "rotate";
      console.log("play454545454545");
    }
    audioAutoPlay();
  },
  { once: true }
);
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@font-face {
  font-family: "sourcehansans";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_0q82cltaxst.eot"); /* IE9*/
  src: url("//at.alicdn.com/t/webfont_0q82cltaxst.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_0q82cltaxst.woff2")
      format("woff2"),
    url("//at.alicdn.com/t/webfont_0q82cltaxst.woff") format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/webfont_0q82cltaxst.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/webfont_0q82cltaxst.svg#思源黑体-粗") format("svg"); /* iOS 4.1- */
}
* {
  font-family: "思源黑体", Helvetica, "Microsoft YaHei", Arial, sans-serif;
}
/*思源黑体,这里是自定义名称，可以起别的名字，比如思源常规都可以*/

body {
  // // height: 9.6533rem;
  // // width: 5rem;
  // width: 100%;
  // height: 100vh;
  // margin: 0;
  // // padding: 0 0.32rem;
  // background-image: url("/src/assets/back.jpg");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // position: relative;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background-image: url("/src/assets/back.gif");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center center;
  position: relative;
}

.logo {
  width: 100%;
  text-align: left;
  padding-top: 0.56rem;
  margin-left: 0.3333rem;
  width: 2.64rem;
  height: 1.0267rem;
}

.van-radio {
  align-items: flex-start !important;
}

.ip {
  padding-left: 0.2667rem;
  padding-top: 0.5rem;
}

// 旋转类
.rotate {
  animation: rotation 3s linear infinite;
}

// 元素持续旋转关键帧
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.img-box {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.5rem;
  right: 0.8rem;

  img {
    width: 100%;
    height: 100%;
  }
}
body {
  background-repeat: no-repeat;
  background-size: cover;
  // height: 100vh;
  background-position: center center;
  position: relative;
  background-image: url("/src/assets/background.jpg");
}
.nav-item-two {
  color: #ffffff;
}
.nav-item {
  color: #166938;
}
.bottomBox {
  position: relative;
  float: left;
  padding-bottom: 0.1rem;
}
.shenpi {
  position: absolute;
  bottom: 0.34rem;
  font-size: 0.3rem;
  color: #595757;
  left: -2.2rem;
  width: 8rem;
  -webkit-transform: scale(0.4);
}
.shenpi2 {
  position: absolute;
  bottom: 0.2rem;
  font-size: 0.3rem;
  color: #595757;
  left: -5.04rem;
  width: 17.6667rem;
  -webkit-transform: scale(0.4);
}
.topshenpi {
  position: absolute;
  bottom: 0.7rem;
  font-size: 0.3rem;
  color: #595757;
  left: -3.6rem;
  width: 11.2rem;
  -webkit-transform: scale(0.4);
}
</style>
