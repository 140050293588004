
import { ref } from "vue";
import { useRouter } from "vue-router";
import question5 from "@/views/QuestionFiveView.vue";
import correct from "@/views/CorrectView.vue";
import correct5 from "@/views/CorrectFiveView.vue";
import reason from "@/views/ReasonView.vue";
import toNav from "@/components/ToNav.vue";
import jiexiao from "@/views/JieXiaoView.vue";
import { showDialog } from "vant";

export default {
  components: { question5, correct, correct5, reason, toNav, jiexiao },
  data() {
    return {
      show: false,
      postaimqus: 0,
      title: "传递的是一个值",
      flag: "question",
      correct: "0",
      chatiShow: true,
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age };
  },
  methods: {
    showPopup() {
      this.$router.push({
        path: "/last",
        // query: { postaimqus: "1", correct: "two" },
      });
    },
  },
};
