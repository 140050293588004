
import { ref } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      radio: "1",
      radioVal: 1,
      aimQus: 0,
      questions: store.state.questions,
    };
  },
  props: ["postaimqus", "correct"],
  setup(props, any) {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age };
  },
  methods: {
    // jump() {},
    nexStep() {
      this.$router.push({
        name: "pinggu",
        // query: { postaimqus: "2", correct: "two" },
      });
    },
    chanti() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "chati",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    pinggu() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "pinggu",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    fangan() {
      console.log("第几道题:" + "评估第一道题结束");
      this.$router.push({
        name: "fangan",
        // query: { postaimqus: "2", correct: "two" },fv
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    sikao() {
      console.log("第几道题:" + "00000888000000");
      this.$router.push({
        name: "sikao",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    shikaoone() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "shikaoone",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    shikaotwo() {
      console.log("第几道题:" + "99999999999");
      this.$router.push({
        name: "shikaotwo",
        // query: { postaimqus: "2", correct: "two" },
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
  },
};
