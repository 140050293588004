import { createStore } from "vuex";

export default createStore({
  state: {
    questions: [
      {
        num: "one",
        bigTitle: "根据患者症状及既往史，可初步认定患者为以下那种疾病？",
        desc: "由于该患儿表现为鼻塞，流大量清涕症状，且伴有眼痒，用药后得到缓解，又反复发作，患者主诉符合变应性鼻炎症状，可初步判断为过敏性疾病。同时普通感冒早期症状以鼻部卡他症状为主，可有喷嚏、鼻塞、流清水样鼻涕等，因而患者也可被初步认定为呼吸道感染性疾病。",
        radioList: [
          {
            name: "1",
            title: "A.呼吸道感染性疾病",
          },
          {
            name: "2",
            title: "B.过敏性疾病",
          },
          {
            name: "3",
            title: "C.以上均可",
          },
        ],
      },
      {
        num: "two",
        bigTitle:
          "你认为该患者的临床诊断为",
        radioList: [
          {
            name: "1",
            title: "A.感染性鼻炎",
          },
          {
            name: "2",
            title: "B.变应性鼻炎",
          },
          {
            name: "3",
            title: "C.普通感冒",
          }, {
            name: "4",
            title: "D.慢性鼻窦炎",
          },

        ],
      },
      {
        num: "four",
        bigTitle:
          "AR与普通感冒有哪些区别？",
          desc: "AR与普通感冒可从发病机制、病程、症状等方面进行鉴别，所采用的诊断方式也有所不同。AR多采用皮肤试验、血清IgE检测、鼻激发试验等方式诊断1。而普通感冒主要依据典型临床症状诊断，实验室检查多采取外周血象、病毒学检查等方式2。",
        radioList: [
          {
            name: "1",
            title: "A.发病原因不同：AR是因为接触过敏原。普通感冒多由病毒引起",
          },
          {
            name: "2",
            title:
              "B.病程不同：AR多为渐起，病程较长。普通感冒起病急，病程短",
          },
          {
            name: "3",
            title: "C.症状不同：AR除鼻部症状外，还可有眼部症状。普通感冒除鼻部症状外，还可以有发热、咽部症状等",
          },
          {
            name: "4",
            title: "D.实验室检查不同：AR多采用过敏原检测及血液检查。普通感冒多以外周血象及病毒学检查",
          },
          {
            name: "5",
            title: "E.以上都是",
          },
        ],
      },
      {
        num: "three",
        desc:"在炎症细胞中，鼻用激素与受体的亲和力与激素的相对效价强度具有良好的相关性。研究发现：丙酸氟替卡松的受体的亲和力为0.5nmol/L，是倍氯米松和糠酸莫米松的1.5倍，布地奈德的3倍，氟尼缩松和曲安奈德的10倍。",
        bigTitle: "以下药物中，受体的亲和力最高的是？",
        radioList: [
          {
            name: "1",
            title: "A.丙酸氟替卡松",
          },
          {
            name: "2",
            title: "B.倍氯米松",
          },
          {
            name: "3",
            title: "C.糠酸莫米松",
          },
          {
            name: "4",
            title: "D.布地奈德",
          },
          {
            name: "5",
            title: "E.曲安奈德",
          },
        ],
      },{
        num: "four",
        desc:"未控制的或重症哮喘为AR免疫治疗的绝对禁忌。得到部分控制的哮喘也属于AR免疫治疗的相对禁忌。哮喘发作也是AR免疫治疗最常见的严重不良反应之一，其他包括腹痛、呕吐、悬雍垂水肿和荨麻疹等。",
        bigTitle: "特异性免疫治疗的禁忌症包括",
        radioList: [
          {
            name: "1",
            title: "A. 未控制的或重症哮喘",
          },
          {
            name: "2",
            title: "B.免疫性疾病活动期",
          },
          {
            name: "3",
            title: "C.恶性肿瘤",
          },
          {
            name: "4",
            title: "D.哮喘得到部分控制",
          },
          {
            name: "5",
            title: "E.以上都是",
          },
        ],
      },{
        num: "five",
        desc:"AR一线治疗药物包括鼻用激素、第二代口服和鼻用抗组胺药、口服白三烯受体拮抗剂。鼻用肥大细胞膜稳定剂属于二线治疗。",
        bigTitle: "以下药物中，全身生物利用度最低的药物是？",
        radioList: [
          {
            name: "1",
            title: "A.倍氯米松",
          },
          {
            name: "2",
            title: "B.糠酸莫米松",
          },
          {
            name: "3",
            title: "C.布地奈德",
          },
          {
            name: "4",
            title: "D.丙酸氟替卡松",
          },
        ],
      },{
        num: "six",
        desc:"与第一代鼻用激素相比，第二代鼻用激素具有高亲脂性、与受体结合力强、抗炎活性更强、生物利用度低等特点。",
        bigTitle: "二代鼻用激素在哪些方面优于一代鼻用激素？",
        radioList: [
          {
            name: "1",
            title: "A.抗炎活性",
          },
          {
            name: "2",
            title: "B. 受体亲和力",
          },
          {
            name: "3",
            title: "C.亲脂性",
          },
          {
            name: "4",
            title: "D.生物利用度",
          },
          {
            name: "5",
            title: "E.以上都是",
          },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
